import { NgModule } from '@angular/core';
import { NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { PageNotFoundComponent } from './page-not-found.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            { path: 'notifications', component: NotificationsComponent },
                            { path: 'page-not-found', component: PageNotFoundComponent },
                            { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                        ],
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then((m) => m.MainModule), //Lazy load main module
                        //data: { preload: true },
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule), //Lazy load admin module
                        //data: { preload: true },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: 'person',
                        loadChildren: () => import('app/person/person.module').then(m => m.PersonModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'company',
                        loadChildren: () => import('app/company/company.module').then(m => m.CompanyModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'billing',
                        loadChildren: () => import('app/billing/billing.module').then(m => m.BillingModule),
                        //data: { preload: true }
                    },
                    {
                        path: 'payroll',
                        loadChildren: () => import('app/payroll/payroll.module').then(m => m.PayrollModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'com-log',
                        loadChildren: () => import('app/com-log/com-log.module').then(m => m.ComLogModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'communication',
                        loadChildren: () => import('app/communication/communication.module').then(m => m.CommunicationModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'contract',
                        loadChildren: () => import('app/contract/contract.module').then(m => m.ContractModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'document',
                        loadChildren: () => import('app/document/document.module').then(m => m.DocumentModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'report',
                        loadChildren: () => import('app/report/report.module').then(m => m.ReportModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'request',
                        loadChildren: () => import('app/request/request.module').then(m => m.RequestModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'timecard',
                        loadChildren: () => import('app/timecard/timecard.module').then(m => m.TimecardModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'base',
                        loadChildren: () => import('app/base/base.module').then(m => m.BaseModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'user',
                        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
                        //data: { preload: true },
                    },
                    {
                        path: 'user-favorite',
                        loadChildren: () => import('./user-favorite/user-favorite.module').then(m => m.UserFavoriteModule),
                        //data: { preload: true },
                    },
                    { 
                        path: 'camunda', 
                        loadChildren: () => import('./camunda/camunda.module').then(m => m.CamundaModule),
                        //data: { preload: true },
                    },
                    {
                        path: '**',
                        redirectTo: 'page-not-found',
                    },
                ],
            }
            

        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router, private spinnerService: NgxSpinnerService) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                spinnerService.show();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
                spinnerService.hide();
            }
        });
    }
}
