import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private _router: Router) { }

  route(path: string, param: any) {
    this._router.navigate([path], param.id);
    console.log(path)
    console.log(param.id)
  }
}
