//* Menu configuration file.
//*
//* Lists the menus and their concatenated routes. Routing to one of these pages will display the related route.
//*
//* Examples
//*  'm-person-dashboard' = '/app/person/dashboard'
//*  'm-person-list' = '/app/person/list'
//*  'm-person-technical-specialist' = '/app/person/technical-specialist'
//*
//* The first item in the array is the menu name. This is used to identify the menu to display in the main-top-menu.component.ts file.
//* The remaining items in the array are the concatenated routes to identify which menu to display.


export const Menus = [
    [
        'main-dashboard-menu',
        'm-main-dashboard',
        'm-main-road-map',
    ],
    [
        'person-menu',
        'm-person-dashboard',
        'm-person-person-list',
        'm-person-person-add-edit'
    ],
    [
        'candidate-menu',
        'm-person-candidate-dashboard'
    ],
    [
        'contact-menu',
        'm-person-contact-dashboard'
    ],
    [
        'ts-menu',
        'm-person-technical-specialist',
        'm-person-technical-specialist-dashboard',
        'm-person-technical-specialist-add-edit',
        'm-person-technical-specialist-technical-specialist-list',
        'm-person-technical-specialist-eye-exam',
        'm-person-technical-specialist-drug-test',
        'm-person-technical-specialist-stamp-control',
        'm-person-technical-specialist-compliance',
        'm-person-technical-specialist-ts-detail',
    ],
    [
        'company-menu',
        'm-company-dashboard'
    ],
    [
        'customer-menu',
        'm-company-customer-dashboard'
    ],
    [
        'program-menu',
        'm-company-customer-program-dashboard'
    ],
    [
        'program-lmc-menu',
        'm-company-customer-program-lmc',
        'm-company-customer-program-lmc-dashboard',
        'm-company-customer-program-lmc-qbm',
        'm-billing-lmc',
        'm-billing-lmc-workfile'
    ],
    [
        'program-parker-menu',
        'm-company-customer-program-parker',
        'm-company-customer-program-parker-dashboard'
    ],
    [
        'program-ge-menu',
        'm-company-customer-program-ge',
        'm-company-customer-program-ge-dashboard'
    ],
    [
        'program-collins-menu',
        'm-company-customer-program-collins',
        'm-company-customer-program-collins-dashboard'
    ],
    [
        'program-pratt-menu',
        'm-company-customer-program-pratt',
        'm-company-customer-program-pratt-dashboard'
    ],
    [
        'program-sqac-menu',
        'm-company-customer-program-sqac',
        'm-company-customer-program-sqac-dashboard'
    ],
    [
        'special-instruction-menu',
        'm-company-customer-special-instruction',
        'm-company-customer-special-instruction-dashboard'
    ],
    [
        'supplier-menu',
        'm-company-supplier',
        'm-company-supplier-dashboard'
    ],
    [
        'request-menu',
        'm-request-dashboard'
    ],
    [
        'request-audit-menu',
        'm-request-audit',
        'm-request-audit-dashboard'
    ],
    [
        'request-inspection-menu',
        'm-request-inspection',
        'm-request-inspection-dashboard'
    ],
    [
        'request-ppap-menu',
        'm-request-ppap',
        'm-request-ppap-dashboard'
    ],
    [
        'request-dsi-menu',
        'm-request-dsi',
        'm-request-dsi-dashboard'
    ],
    [
        'report-menu',
        'm-report',
        'm-report-dashboard',
    ],
    [
        'timecard-menu',
        'm-timecard',
        'm-timecard-dashboard',
        'm-timecard-list',
        'm-timecard-submitted',
        'm-timecard-import',
        'm-timecard-data-check',
        'm-timecard-in-process',
        'm-timecard-processed',
    ],
    [
        'document-menu',
        'm-document',
        'm-document-dashboard'
    ],
    [
        'com-log-menu',
        'm-com-log',
        'm-com-log-dashboard'
    ],
    [
        'communication-menu',
        'm-communication',
        'm-communication-dashboard'
    ],
]
