import { FormGroup, ValidatorFn } from '@angular/forms';


export class NoValueValidator {
    public noValues(): ValidatorFn {
        return (formGroup: FormGroup) => {
            const stdControl = formGroup.get('standard_hours');
            const otControl = formGroup.get('overtime_hours');
            const trvlControl = formGroup.get('travel_hours');
            const milesControl = formGroup.get('miles');
            const airfareControl = formGroup.get('airfare');
            const mealsControl = formGroup.get('meals');
            const carControl = formGroup.get('car_rental');
            const parkControl = formGroup.get('parking_tolls');
            const phoneControl = formGroup.get('phone_fax');
            const hotelControl = formGroup.get('hotel');
            const postageControl = formGroup.get('postage');
            const otherControl = formGroup.get('other');
            const fourHmControl = formGroup.get('four_hour_minimum');

            if(
                (stdControl.value == '0' || stdControl.value == '') &&
                (otControl.value == '0' || otControl.value == '') &&
                (trvlControl.value == '0' || trvlControl.value == '') &&
                (milesControl.value == '0' || milesControl.value == '') &&
                (airfareControl.value == '0' || airfareControl.value == '') &&
                (mealsControl.value == '0' || mealsControl.value == '') &&
                (carControl.value == '0' || carControl.value == '') &&
                (parkControl.value == '0' || parkControl.value == '') &&
                (phoneControl.value == '0' || phoneControl.value == '') &&
                (hotelControl.value == '0' || hotelControl.value == '') &&
                (postageControl.value == '0' || postageControl.value == '') &&
                (otherControl.value == '0' || otherControl.value == '') &&
                (fourHmControl.value == '0' || fourHmControl.value == '')
            ) {
                return { noValues: true };
            } else {
                return null;
            }
        }
    }
}