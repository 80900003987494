import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { Observable } from 'rxjs';
import { TimecardList } from '@app/_model/timecard-list.model';
import { TimecardImportSummary } from '@app/_model/timecard-import-summary.model';
import { CompanyGroup } from '@app/_model/company-group.model';
import { ToggleList } from '@app/_model/toggle-list.model';
import { LegacyTimecardList } from '@app/_model/timecard-list-legacy.model';

@Injectable({
    providedIn: 'root'
})
export class TimecardService {

    page: string;
    pageTitle: string;
    pageSubTitle: string;
    timecardList: TimecardList[] = [];
    companyGroupList: any[] = [];
    toggleList: string[] = [];
    toggleValues: any[] = [];

    constructor(private _httpBaseService: HttpBaseService) { }

    getPage(p: string) {
        this.page = p;
    }

    getTimecardImportSummary(): Observable<TimecardImportSummary> {
        return this._httpBaseService.get('timecard/importsummary');
    }

    getTimecardImportList(): Observable<TimecardList[]> {
        return this._httpBaseService.get('timecard/importlist');
    }
    
    getInProcessSummary(): Observable<TimecardImportSummary> {
        return this._httpBaseService.get('timecard/inprocesssummary');
    }

    getInProcessList(): Observable<TimecardList[]> {
        return this._httpBaseService.get('timecard/inprocesslist');
    }

    getCompanyGroupList(): Observable<CompanyGroup[]> {
        return this._httpBaseService.get('company-group/list');
    }

    getToggleList(): Observable<ToggleList[]> {
        return this._httpBaseService.get('timecard/togglelist');
    }

    getToggleValues(type: string): Observable<any[]> {
        return this._httpBaseService.post('timecard/gettogglevalues', { "type": type });
    }

    setToggleValues(type: string, holdStatus: boolean, id: string, comment: string): Observable<any[]> {
        return this._httpBaseService.post('timecard/settogglevalues', { "type": type, "holdStatus": holdStatus, "value": id, "userId": abp.session.userId, "comment": comment });
    }

    toggleAllHold({ "hold": boolean }) {
        return this._httpBaseService.get('company-group/list');
    }

    toggleAllInProcessHold({ "hold": boolean }) {
        return this._httpBaseService.get('company-group/list');
    }
}
