import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
    providedIn: 'root'
})
export class CandidateService {

    constructor(private _httpBaseService: HttpBaseService) { }

    getCandidates(): any[] {
        return;
    }
}
