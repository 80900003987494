<div class="grid">
    <div class="col-2"></div>
    <div class="col-2"></div>
    <div class="col-2">
        <!-- <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route[1],'lmc-workfile', logo)" type="button"><fa-icon [icon]="icon.farFolderGear" class="mr-1"></fa-icon>{{ 'Work File' | localize }}</button> -->
    </div>
    <div class="col-2">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route[1],'lmc', logo)" type="button"><fa-icon [icon]="icon.farFileInvoice" class="mr-1"></fa-icon>{{ 'Billing' | localize }}</button>
    </div>
    <div class="col-2">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route[0],'qbm', logo)" type="button"><fa-icon [icon]="icon.farBallotCheck" class="mr-1"></fa-icon>{{ 'Qbm' | localize }}</button>
    </div>
    <div class="col-2">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route[0],'dashboard', logo)" type="button"><fa-icon [icon]="icon.farChartLine" class="mr-1"></fa-icon>{{ 'Dashboard' | localize }}</button>
    </div>
</div>