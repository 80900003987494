import { Component, OnInit } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'timecard-modal',
    templateUrl: './timecard-modal.component.html',
    styleUrls: ['./timecard-modal.component.css'],
    animations: [appModuleAnimation()]
})
export class TimecardModalComponent implements OnInit {

    modalTitle: string;
    active: boolean = false;
    saving: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

    show() {
    }

    onShown() {
    }

    close() {
    }

    save() {
    }

}
