import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'kt-scroll-top',
    templateUrl: './scroll-top.component.html',
})
export class ScrollTopComponent extends AppComponentBase {

    constructor(injector: Injector) {
        super(injector);
    }
}