import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { Observable } from 'rxjs';
import { CompanyList } from '@app/_model/company-list.model';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(private _httpBaseService: HttpBaseService) { }

    getCompanyList(): Observable<CompanyList[]> {
        return this._httpBaseService.get('company/list');
    }
}
