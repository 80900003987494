import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { PersonService } from '@app/_service/person.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Location } from '@angular/common';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'app-main-dashboard-top-menu',
  templateUrl: './main-dashboard-top-menu.component.html',
  styleUrls: ['./main-dashboard-top-menu.component.css'],
    animations: [appModuleAnimation()]
})
export class MainDashboardTopMenuComponent extends AppComponentBase {

    activeNav = this._route.snapshot.url[this._route.snapshot.url.length - 1].path

    constructor(
        injector: Injector,
        //private _personService: PersonService,
        private _route: ActivatedRoute,
        private _router: Router,
        private location: Location
    ) {
        super(injector);
    }

    navigate(route) {
        //if (route == 'main-dashboard') {
        //    this._personService.selectedPersonId = -1;
        //}
        let p = this.location.path();
        let path = p.substring(0, p.lastIndexOf("/") + 1)
        this._router.navigate([path + route]);
    }
}
