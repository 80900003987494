import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PersonService } from './person.service';

@Injectable({
  providedIn: 'root'
})
export class AppHeaderService {

    selectedPage: string = 'dashboard';
    menuSelected: string = '';
    
    logoPath = '../../../../assets/common/images/logos/';
    //logoPath = '/src/assets/common/images/logos/';
    headerMain: string;
    headerSub: string;
    headerLogoImage: string = '';
    headerClass: string;
    headerLogo: string; // = `${this.logoPath}${this.headerLogoImage}`;

    routeSeg:string[] = [];
    
  constructor(
    private router: Router,
    private _personService: PersonService) { }

  /**
 *
 * @param route  The route path, e.g. 'app/person/technical-specialist/'.
 * @param page  The page name, e.g. 'ts-list'.
 * @returns A useful value.
 * 
 */  
  navigate(route: string, page: string, logo: string) {
    console.log('logo',logo);
    console.log('route',route);
    this.headerLogoImage = logo;
    this.headerLogo = `${this.logoPath}${this.headerLogoImage}`;
    console.log('headerLogo',this.headerLogo);

    if (page == 'person-add-edit') {
        this._personService.selectedPersonId = -1;
    }

    this.selectedPage = page;
    console.log(`${route}${page}`);
    this.router.navigate([`${route}${page}`]);
 }

}
