import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'badge'
})
export class BadgePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    switch (value) {
        case 'Active':
          return 'badge-success';
        case 'Inactive':
          return 'badge-warning';
        case 'Terminated':
          return 'badge-dark';
        default:
          return 'badge-default';
      }
  }
}
