import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'app-program-lmc-top-menu',
  templateUrl: './program-lmc-top-menu.component.html',
  styleUrls: ['./program-lmc-top-menu.component.css'],
    animations: [appModuleAnimation()]
})
export class ProgramLmcTopMenuComponent extends AppComponentBase {

    route: string[] = [
        '/app/company/customer/program/lmc/',
        '/app/billing/'
        ];
    logo: string = 'lmc_logo.svg';

    constructor(injector: Injector) {
        super(injector);
    }
    
}