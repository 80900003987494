import { Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpBaseService } from './http-base.service';
import { ViewerComponent } from '@app/report/viewer/viewer.component';
import { ReportList } from '@app/_model/report-list.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    @ViewChild('viewer') viewer: ViewerComponent;

    reportName: string;

    constructor(private _httpBaseService: HttpBaseService, private router: Router) { }

    getReportList(): Observable<ReportList[]> {
        const apiUrl = 'report/list';
        return this._httpBaseService.get(apiUrl);
        //return this.http.get<ReportList[]>(environment.nApi + 'report/list', { headers: environment.nApiHeaders });
    }





    setReportName(e): void {
        this.reportName = e;
        this.router.navigateByUrl('/app/report/viewer');
    }
}
