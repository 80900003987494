<div class="grid" style="width:100%;">
    <div class="col-5 pt-1">
        <span class="headerMain">{{_appHeader.headerMain | titlecase}}</span> <span class="headerSub">{{_appHeader.headerSub | uppercase}}</span>
        <span *ngIf="_appHeader.headerLogoImage" class="mr-1"><br><img [src]="_appHeader.headerLogo" height="30px" alt="logo" class="logo"></span>
    </div>
    <div class="col-7 pt-7">
        <app-candidate-top-menu             class="width-percent-100 text-right" *ngIf="selectedMenu=='candidate-menu'">                </app-candidate-top-menu>
        <app-com-log-top-menu               class="width-percent-100 text-right" *ngIf="selectedMenu=='com-log-menu'">                  </app-com-log-top-menu>
        <app-communication-top-menu         class="width-percent-100 text-right" *ngIf="selectedMenu=='communication-menu'">            </app-communication-top-menu>
        <app-company-top-menu               class="width-percent-100 text-right" *ngIf="selectedMenu=='company-menu'">                  </app-company-top-menu>
        <app-contact-top-menu               class="width-percent-100 text-right" *ngIf="selectedMenu=='contact-menu'">                  </app-contact-top-menu>
        <app-customer-top-menu              class="width-percent-100 text-right" *ngIf="selectedMenu=='customer-menu'">                 </app-customer-top-menu>
        <app-document-top-menu              class="width-percent-100 text-right" *ngIf="selectedMenu=='document-menu'">                 </app-document-top-menu>
        <app-main-dashboard-top-menu        class="width-percent-100 text-right" *ngIf="selectedMenu=='main-dashboard-menu'">           </app-main-dashboard-top-menu>
        <app-person-top-menu                class="width-percent-100 text-right" *ngIf="selectedMenu=='person-menu'">                   </app-person-top-menu>
        <app-program-collins-top-menu       class="width-percent-100 text-right" *ngIf="selectedMenu=='program-collins-menu'">          </app-program-collins-top-menu>
        <app-program-ge-top-menu            class="width-percent-100 text-right" *ngIf="selectedMenu=='program-ge-menu'">               </app-program-ge-top-menu>
        <app-program-lmc-top-menu           class="width-percent-100 text-right" *ngIf="selectedMenu=='program-lmc-menu'">              </app-program-lmc-top-menu>
        <app-program-parker-top-menu        class="width-percent-100 text-right" *ngIf="selectedMenu=='program-parker-menu'">           </app-program-parker-top-menu>
        <app-program-pratt-top-menu         class="width-percent-100 text-right" *ngIf="selectedMenu=='program-pratt-menu'">            </app-program-pratt-top-menu>
        <app-program-sqac-top-menu          class="width-percent-100 text-right" *ngIf="selectedMenu=='program-sqac-menu'">             </app-program-sqac-top-menu>
        <app-program-top-menu               class="width-percent-100 text-right" *ngIf="selectedMenu=='program-menu'">                  </app-program-top-menu>
        <app-recruiting-top-menu            class="width-percent-100 text-right" *ngIf="selectedMenu=='recruiting-menu'">               </app-recruiting-top-menu>
        <app-referral-top-menu              class="width-percent-100 text-right" *ngIf="selectedMenu=='referral-menu'">                 </app-referral-top-menu>
        <app-report-top-menu                class="width-percent-100 text-right" *ngIf="selectedMenu=='report-menu'">                   </app-report-top-menu>
        <app-request-audit-top-menu         class="width-percent-100 text-right" *ngIf="selectedMenu=='request-audit-menu'">            </app-request-audit-top-menu>
        <app-request-dsi-top-menu           class="width-percent-100 text-right" *ngIf="selectedMenu=='request-dsi-menu'">              </app-request-dsi-top-menu>
        <app-request-inspection-top-menu    class="width-percent-100 text-right" *ngIf="selectedMenu=='request-inspection-menu'">       </app-request-inspection-top-menu>
        <app-request-ppap-top-menu          class="width-percent-100 text-right" *ngIf="selectedMenu=='request-ppap-menu'">             </app-request-ppap-top-menu>
        <app-request-top-menu               class="width-percent-100 text-right" *ngIf="selectedMenu=='request-menu'">                  </app-request-top-menu>
        <app-special-instruction-top-menu   class="width-percent-100 text-right" *ngIf="selectedMenu=='special-instruction-menu'">      </app-special-instruction-top-menu>
        <app-supplier-top-menu              class="width-percent-100 text-right" *ngIf="selectedMenu=='supplier-menu'">                 </app-supplier-top-menu>
        <app-ts-top-menu                    class="width-percent-100 text-right" *ngIf="selectedMenu=='ts-menu'">                       </app-ts-top-menu>
        <app-timecard-top-menu              class="width-percent-100 text-right" *ngIf="selectedMenu=='timecard-menu'">                 </app-timecard-top-menu>
    </div>
</div> 

