import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
    providedIn: 'root'
})
export class ComLogService {

    constructor(private _httpBaseService: HttpBaseService) { }
}
