import { Component, Injector } from '@angular/core';
import { NavigationStart, Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Menus } from './menus';
import { RouteSegmentLogo } from './route-segment-logo';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'app-main-top-menu',
    templateUrl: './main-top-menu.component.html',
    styleUrls: ['./main-top-menu.component.css'],
  animations: [appModuleAnimation()]
})
export class MainTopMenuComponent extends AppComponentBase {

    menus = Menus;
    routeSegmentLogo = RouteSegmentLogo;

    selectedMenu: string;
    lastRoute: string;

    constructor(
        injector: Injector,
        private router: Router,
    ) {
        super(injector);

        this.getRouteSnapshot(); //* Gets the initial route in case of page refresh. Once on page load.

        //* Gets the route on every navigationstart
        router.events.subscribe((event: NavigationStart) => {
            
            console.log('event.url',event.url)

            if (event.url && this.lastRoute !== event.url) {
                this.lastRoute = event.url;
                this._appHeader.menuSelected = 'm';
                this.selectedMenu = '';
                this._appHeader.routeSeg = [];

                const seg: string[] = [];

                this._appHeader.headerLogoImage = '';
                this._appHeader.headerLogo = '';

                event.url.split('/').forEach(s => {

                    this.routeSegmentLogo.forEach(e => {
                        if (e.seg == s) {
                            this._appHeader.headerLogoImage = e.logo;
                            this._appHeader.headerLogo = `${this._appHeader.logoPath}${this._appHeader.headerLogoImage}`;

                            
                            console.log('this._appHeader.headerLogoImage',this._appHeader.headerLogoImage);
                            console.log('this._appHeader.headerLogo',this._appHeader.headerLogo);
                        }
                    });

                    this._appHeader.routeSeg.push(s);
                    //seg.push(s.replace('-', ' '));
                    seg.push(s.replace(/#|-/g, ' '));
                    this._appHeader.menuSelected = s == 'app' || s == '' ? this._appHeader.menuSelected : this._appHeader.menuSelected.concat('-', s);
                });

                this.menus.forEach(e => {
                    if (e.some(x => x == this._appHeader.menuSelected)) {
                        this.selectedMenu = e[0];
                    }
                })


                this._appHeader.headerMain = seg[seg.length - 2]
                this._appHeader.headerSub = seg.pop();

                if (this._appHeader.headerMain === 'app') {
                    this._appHeader.headerMain = this._appHeader.headerSub;
                    this._appHeader.headerSub = '';
                } else if (this._appHeader.headerSub == this._appHeader.headerMain) {
                    this._appHeader.headerSub = '';
                } else {
                    this._appHeader.headerSub = this._appHeader.headerSub;
                }

                this._appHeader.headerMain = this._appHeader.headerMain.replace('person', 'people');
            }
        }
        );
    }

    getRouteSnapshot() {
        const state: RouterState = this.router.routerState;
        const snapshot: RouterStateSnapshot = state.snapshot;

        if (snapshot.url && this.lastRoute !== snapshot.url) {
            this.lastRoute = snapshot.url;
            this._appHeader.menuSelected = 'm';
            this.selectedMenu = '';

            this._appHeader.routeSeg = [];
            const seg: string[] = [];

            this._appHeader.headerLogoImage = '';
            this._appHeader.headerLogo = '';

            snapshot.url.split('/').forEach(s => {
                
                this.routeSegmentLogo.forEach(e => {
                    if (e.seg == s) {
                        this._appHeader.headerLogoImage = e.logo;
                        this._appHeader.headerLogo = `${this._appHeader.logoPath}${this._appHeader.headerLogoImage}`;
                    }
                });

                this._appHeader.routeSeg.push(s);;
                //seg.push(s.replace('-', ' '));
                seg.push(s.replace(/#|-/g, ' '));
                this._appHeader.menuSelected = s == 'app' || s == '' ? this._appHeader.menuSelected : this._appHeader.menuSelected.concat('-', s);
            });

            this.menus.forEach(e => {
                if (e.some(x => x == this._appHeader.menuSelected)) {
                    this.selectedMenu = e[0];
                }
            })

            this._appHeader.headerMain = seg[seg.length - 2]
            this._appHeader.headerSub = seg.pop();

            if (this._appHeader.headerMain === 'app') {
                this._appHeader.headerMain = this._appHeader.headerSub;
                this._appHeader.headerSub = '';
            } else if (this._appHeader.headerSub == this._appHeader.headerMain) {
                this._appHeader.headerSub = '';
            } else {
                this._appHeader.headerSub = this._appHeader.headerSub;
            }
            this._appHeader.headerMain = this._appHeader.headerMain.replace('person', 'people');
        }
    }

}