<div class="grid">
    <div class="col-2"></div>
    <div class="col-2" *ngIf="'Pages.UT.People.TechnicalSpecialists.List' | permission">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route, 'list',logo)" type="button"><i class="fa fa-list-alt"></i>&nbsp;TS List</button>
    </div>
    <div class="col-2" *ngIf="'Pages.UT.People.TechnicalSpecialists.EyeExams' | permission">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route, 'eye-exam',logo)" type="button"><i class="fa fa-eye-low-vision"></i>&nbsp;Eye Exams</button>
    </div>
    <div class="col-2" *ngIf="'Pages.UT.People.TechnicalSpecialists.DrugTesting' | permission">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route, 'drug-test',logo)" type="button"><i class="fa fa-cannabis"></i>&nbsp;Drug Testing</button>
    </div>
    <div class="col-2" *ngIf="'Pages.UT.People.TechnicalSpecialists.StampControl' | permission">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route, 'stamp-control',logo)" type="button"><i class="fa fa-stamp"></i>&nbsp;Stamp Control</button>
    </div>
    <div class="col-2" *ngIf="'Pages.UT.People.TechnicalSpecialists.Dashboard' | permission">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route, 'dashboard',logo)" type="button"><fa-icon [icon]="icon.farChartLine" class="mr-1"></fa-icon>Dashboard</button>
    </div>
</div>
