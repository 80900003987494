import { Observable } from 'rxjs';
import { HttpBaseService } from './http-base.service';
import { Injectable } from '@angular/core';
import { BillingHistory } from '@app/_model/billing-history.model';
import { BillingDashboard } from '@app/_model/billing-dashboard.model';
import { WorkfileSummary } from '@app/_model/workfile-summary.model';
import { Workfile } from '@app/_model/workfile.model';

@Injectable({
    providedIn: 'root'
})
export class BillingService {
    
    constructor(private _httpBaseService: HttpBaseService) { }

    lmcBillingLast12Weeks(): Observable<BillingHistory[]> {
        return this._httpBaseService.get('billing/lmc-last-12');
    }
    
    lmcBillingDashboard(): Observable<BillingDashboard> {
        return this._httpBaseService.get('billing/lmc-billing-dashboard');
    }
    
    lmcBillingWorkfileSummary(): Observable<WorkfileSummary> {
        return this._httpBaseService.get('billing/lmc-workfile-summary');
    }
    
    lmcBillingWorkfileData(): Observable<Workfile> {
        return this._httpBaseService.get('billing/lmc-workfile-data');
    }
    
    getWorkfileRecordDetail(id: number): Observable<Workfile> {
        return this._httpBaseService.get(`billing/lmc-workfile-detail/${id}`);
    }

    createWorkfile(currentWeek: number, weekEndingDate: string): Observable<any[]> {
        return this._httpBaseService.post('billing/create-workfile', { "currentWeek": currentWeek, "weekEndingDate": weekEndingDate });
    }

    updateWorkfile(detail: Workfile): Observable<any> {
        return this._httpBaseService.post('billing/update-workfile', detail);
    }
}
