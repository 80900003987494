import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Person } from '@app/_model/person.model';
import { PersonName } from '@app/_model/person-name.model';
import { PersonsLastTen } from '@app/_model/person-last-ten.model';
import { LatLng } from '@app/_model/lat-lng.model';
import { PersonList } from '@app/_model/person-list.model';
import { PersonDetail } from '@app/_model/person-detail.model';
import { HttpBaseService } from './http-base.service';
import { NamePrefix } from '@app/_model/name-prefix.model';
import { NameSuffix } from '@app/_model/name-suffix.model';

//import { LocalStorageService } from '@shared/utils/local-storage.service';
//import { AppSessionService } from '../../shared/common/session/app-session.service';
//import { CommonService } from '@app/_service/common.service';
//import { environment } from 'environments/environment';
//import { ErrorHandlerService } from '@app/_service/error-handler.service';
//import { MainService } from '@app/shared/common/_services/main.service';
//import { catchError } from 'rxjs/operators';
//import { PersonSchedule } from '@app/_model/person-schedule.model';
//import { ReferralPending } from '@app/referrals/_models/referral.model';
//import { AngularFirestore, AngularFirestoreCollection, QuerySnapshot } from '@angular/fire/compat/firestore';
//import { AngularFireAuth } from '@angular/fire/compat/auth';
//import { Firestore, collectionData, collection } from '@angular/fire/firestore';
//import { Collection } from 'lodash';
//import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class PersonService {

    person: PersonDetail;
    query: string;
    position: string;
    locations: Array<any>;
    address = '';
    tempLat: string;
    tempLng: string;
    selectedPersonId: number;
    selectedPersonName: string;
    currentPage: string;


    //private personCollection: AngularFirestoreCollection<Person>;
    //private personCollection = collection(this.db,'persons')

    personList: Observable<Person[]>;

    constructor(
        //private _router: Router,
        private _httpBaseService: HttpBaseService,
        //private errorHandlerService: ErrorHandlerService, 
        //private _mainService: MainService,
        //public afAuth: AngularFireAuth,
        //private db: AngularFirestore,

    ) {
    }


    // getPersonNamesAF() {
    //     //this.personList = collectionData(this.personCollection);
    //     this.personCollection =
    //         this.db.collection<Person>('persons');
    //     this.personList = this.personCollection.valueChanges()
    // }

    getPersonList(): Observable<PersonList[]> {
        return this._httpBaseService.get('person/list');
    }

    getPersonNames(allStatus, statusId): Observable<PersonName[]> {
        return this._httpBaseService.get('person/get-person-names/' + allStatus + '/' + statusId);
    }

    getPersonById(personId): Observable<PersonDetail> {
        return this._httpBaseService.get('person/get-person-by-id/' + personId + '/' + abp.session.userId);
    }

    getPersonsLastTen(): Observable<PersonsLastTen[]> {
        return this._httpBaseService.get('person/get-persons-last-ten');
    }

    updatePersonLatLng(latLng: LatLng): Observable<any> {
        return this._httpBaseService.put('person/update-person-lat-lng/' + latLng.id, latLng);
    }

}
