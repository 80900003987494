import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'flaticon-line-graph',
                '/app/admin/hostDashboard'
            ),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem(
                'Administration',
                'Pages.Administration',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'System Messages',
                        'Pages.Administration',
                        'flaticon-speech-bubble',
                        '/app/admin/system-message'
                    ),
                    new AppMenuItem(
                        'Reference Tables',
                        'Pages.Administration.ReferenceTables',
                        'flaticon-layer',
                        '/app/admin/reference-table'
                    ),
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Administration.OrganizationUnits',
                        'flaticon-map',
                        '/app/admin/organization-units'
                    ),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'flaticon-tabs',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        'flaticon-folder-1',
                        '/app/admin/auditLogs'
                    ),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'flaticon-lock',
                        '/app/admin/maintenance'
                    ),
                    new AppMenuItem(
                        'Subscription',
                        'Pages.Administration.Tenant.SubscriptionManagement',
                        'flaticon-refresh',
                        '/app/admin/subscription-management'
                    ),
                    new AppMenuItem(
                        'VisualSettings',
                        'Pages.Administration.UiCustomization',
                        'flaticon-medical',
                        '/app/admin/ui-customization'
                    ),
                    new AppMenuItem(
                        'WebhookSubscriptions',
                        'Pages.Administration.WebhookSubscription',
                        'flaticon2-world',
                        '/app/admin/webhook-subscriptions'
                    ),
                    new AppMenuItem(
                        'DynamicProperties',
                        'Pages.Administration.DynamicProperties',
                        'flaticon-interface-8',
                        '/app/admin/dynamic-property'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Host.Settings',
                        'flaticon-settings',
                        '/app/admin/hostSettings'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'flaticon-settings',
                        '/app/admin/tenantSettings'
                    )
                ]
            ),
            /* new AppMenuItem(
                'DemoUiComponents',
                'Pages.DemoUiComponents',
                'flaticon-shapes',
                '/app/admin/demo-ui-components'
            ), */
            /* new AppMenuItem(
                'Base',
                '',
                'fa-regular n-fa-larger fa-x',
                '',
                [],
                [
                    new AppMenuItem(
                        'Dashboard',
                        '',
                        'fa-regular n-fa-larger fa-chart-line',
                        '/app/base/dashboard'
                    ),
                    new AppMenuItem(
                        'Example One(Module & Component)',
                        '',
                        'fa-regular n-fa-larger fa-x',
                        '/app/base/example-one'
                    ),
                    new AppMenuItem(
                        'Example Two(Component Only)',
                        '',
                        'fa-regular n-fa-larger fa-x',
                        '/app/base/example-two'
                    ),
                ]
            ), */
            /* new AppMenuItem(
                'Camunda',
                '',
                'fa-regular n-fa-larger fa-c',
                '',
                [],
                [
                    new AppMenuItem(
                        'Dashboard',
                        '',
                        'fa-regular n-fa-larger fa-chart-line',
                        '/app/camunda/dashboard'
                    ),
                    new AppMenuItem(
                        'Diagrams',
                        '',
                        'fa-regular n-fa-larger fa-d',
                        '/app/camunda/diagram'
                    ),
                    new AppMenuItem(
                        'Forms',
                        '',
                        'fa-regular n-fa-larger fa-f',
                        '/app/camunda/form'
                    ),
                ]
            ), */
            new AppMenuItem(
                'People',
                'Pages.UT.People',
                'fa-regular n-fa-larger fa-poll-people',
                '',
                [],
                [
                    new AppMenuItem(
                        'Dashboard',
                        'Pages.UT.People',
                        'fa-regular n-fa-larger fa-chart-line',
                        '/app/person/dashboard'
                    ),
                    new AppMenuItem(
                        'Candidates',
                        'Pages.UT.People.Candidates',
                        'fa-regular n-fa-larger fa-poll-people',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Dashboard',
                                'Pages.UT.People.Candidates',
                                'fa-regular n-fa-larger fa-chart-line',
                                '/app/person/candidate/dashboard'
                            ),
                            new AppMenuItem(
                                'Recruiting',
                                'Pages.UT.People.Recruiting',
                                'fa-regular n-fa-larger fa-search-location',
                                '/app/person/candidate/recruit'
                            ),
                            new AppMenuItem(
                                'Referrals',
                                'Pages.UT.People.Referrals',
                                'fa-regular n-fa-larger fa-user-tag',
                                '/app/person/candidate/referral'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'Technical Specialists',
                        'Pages.UT.People.TechnicalSpecialists',
                        'fa-regular n-fa-larger fa-id-badge',
                        '/app/person/technical-specialist/dashboard'
                    ),
                    new AppMenuItem(
                        'Contacts',
                        'Pages.UT.People.Contacts',
                        'fa-regular n-fa-larger fa-address-card ',
                        '/app/person/contact'
                    ),
                ]
            ),

            new AppMenuItem(
                'Companies',
                'Pages.UT.Companies',
                'fa-regular n-fa-larger fa-building',
                '',
                [],
                [
                    new AppMenuItem(
                        'Dashboard',
                        'Pages.UT.Companies.Dashboard',
                        'fa-regular n-fa-larger fa-chart-line',
                        '/app/company/dashboard'
                    ),
                    new AppMenuItem(
                        'Customers',
                        'Pages.UT.Companies.Customers',
                        'fa-regular n-fa-larger fa-handshake',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Dashboard',
                                'Pages.UT.Companies.Customers.Dashboard',
                                'fa-regular n-fa-larger fa-chart-line',
                                '/app/company/customer/dashboard'
                            ),
                            new AppMenuItem(
                                'Programs',
                                'Pages.UT.Companies.Customers.Programs',
                                'fa-regular n-fa-larger fa-comment-dollar',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'LMC',
                                        'Pages.UT.Companies.Customers.Programs.Lmc',
                                        'fa-regular n-fa-larger fa-chart-line',
                                        '/app/company/customer/program/lmc'
                                    ),
                                    new AppMenuItem(
                                        'Parker',
                                        'Pages.UT.Companies.Customers.Programs.Parker',
                                        'fa-regular n-fa-larger fa-chart-line',
                                        '/app/company/customer/program/parker'
                                    ),
                                    new AppMenuItem(
                                        'Ge',
                                        'Pages.UT.Companies.Customers.Programs.Ge',
                                        'fa-regular n-fa-larger fa-chart-line',
                                        '/app/company/customer/program/ge'
                                    ),
                                    new AppMenuItem(
                                        'Collins',
                                        'Pages.UT.Companies.Customers.Programs.Collins',
                                        'fa-regular n-fa-larger fa-chart-line',
                                        '/app/company/customer/program/collins'
                                    ),
                                    new AppMenuItem(
                                        'Pratt',
                                        'Pages.UT.Companies.Customers.Programs.Pratt',
                                        'fa-regular n-fa-larger fa-chart-line',
                                        '/app/company/customer/program/pratt'
                                    ),
                                    new AppMenuItem(
                                        'Sqac',
                                        'Pages.UT.Companies.Customers.Programs.Sqac',
                                        'fa-regular n-fa-larger fa-chart-line',
                                        '/app/company/customer/program/sqac'
                                    ),
                                ]
                            ),
                            new AppMenuItem(
                                'Special Instructions',
                                'Pages.Administration.ReferenceTables.SpecialInstructions',
                                'fa-regular n-fa-larger fa-x',
                                '/app/company/customer/special-instruction'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'Suppliers',
                        'Pages.UT.Companies.Suppliers',
                        'fa-regular n-fa-larger fa-warehouse',
                        '/app/company/supplier'
                    )
                ]
            ),

            new AppMenuItem(
                'Contracts',
                'Pages.UT.Contracts',
                'fa-regular n-fa-larger fa-file-contract',
                '',
                [],
                [
                    new AppMenuItem(
                        'Dashboard',
                        'Pages.UT.Contracts',
                        'fa-regular n-fa-larger fa-chart-line',
                        '/app/contract/dashboard'
                    ),
                    new AppMenuItem(
                        'Jobs',
                        'Pages.UT.Contracts.Jobs',
                        'fa-regular n-fa-larger fa-briefcase',
                        '/app/contract/job'
                    ),
                ]
            ),

            new AppMenuItem(
                'Requests',
                'Pages.UT.Requests',
                'fa-regular n-fa-larger fa-calendar-users',
                '',
                [],
                [
                    new AppMenuItem(
                        'Dashboard',
                        'Pages.UT.Requests.RequestDashboard',
                        'fa-regular n-fa-larger fa-chart-line',
                        '/app/request/dashboard'
                    ),
                    new AppMenuItem(
                        'Audits',
                        'Pages.UT.Requests.Audits',
                        'fa-regular n-fa-larger fa-passport',
                        '/app/request/audit'
                    ),
                    new AppMenuItem(
                        'Inspections',
                        'Pages.UT.Requests.Inspections',
                        'fa-regular n-fa-larger fa-ballot-check',
                        '/app/request/inspection'
                    ),
                    new AppMenuItem(
                        'PPAP',
                        'Pages.UT.Requests.Ppap',
                        'fa-regular n-fa-larger fa-p',
                        '/app/request/ppap'
                    ),
                    new AppMenuItem(
                        'DSI',
                        'Pages.UT.Requests.Dsi',
                        'fa-regular n-fa-larger fa-d',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Candidates',
                                'Pages.UT.Requests.Dsi.Candidates',
                                'fa-regular n-fa-larger fa-c',
                                '/app/request/dsi/candidate'
                            ),
                            new AppMenuItem(
                                'FAQs',
                                'Pages.UT.Requests.Dsi.Faqs',
                                'fa-regular n-fa-larger fa-f',
                                '/app/request/dsi/faq'
                            ),
                        ]
                    ),
                ]
            ),

            new AppMenuItem(
                'Timecards',
                'Pages.UT.Timecards',
                'fa-regular n-fa-larger fa-clock',
                '',
                [],
                [
                    new AppMenuItem(
                        'Dashboard',
                        'Pages.UT.Timecards',
                        'fa-regular n-fa-larger fa-chart-line',
                        '/app/timecard/dashboard'
                    ),
                    new AppMenuItem(
                        'Submitted',
                        'Pages.UT.Timecards.Submitted',
                        'fa-regular n-fa-larger fa-file-arrow-up',
                        '/app/timecard/submitted'
                    ),
                    new AppMenuItem(
                        'Import',
                        'Pages.UT.Timecards.Import',
                        'fa-regular n-fa-larger fa-download',
                        '/app/timecard/import'
                    ),
                    new AppMenuItem(
                        'Data Checks',
                        'Pages.UT.Timecards.DataChecks',
                        'fa-regular n-fa-larger fa-file-circle-question',
                        '/app/timecard/data-check'
                    ),
                    new AppMenuItem(
                        'In-Process',
                        'Pages.UT.Timecards.InProcess',
                        'fa-regular n-fa-larger fa-file-lines',
                        '/app/timecard/in-process'
                    ),
                    new AppMenuItem(
                        'Processed',
                        'Pages.UT.Timecards.Processed',
                        'fa-regular n-fa-larger fa-file-circle-check',
                        '/app/timecard/processed'
                    ),
                ]
            ),

            new AppMenuItem(
                'Billing',
                'Pages.UT.Billing',
                'fa-regular n-fa-larger fa-file-invoice',
                '',
                [],
                [
                    new AppMenuItem(
                        'Dashboard',
                        'Pages.UT.Billing',
                        'fa-regular n-fa-larger fa-chart-line',
                        '/app/billing/dashboard',
                    ),
                    new AppMenuItem(
                        'Invoices',
                        'Pages.UT.Billing.Invoices',
                        'fa-regular n-fa-larger fa-file-invoice-dollar',
                        '/app/billing/invoice',
                    ),
                ]
            ),

            new AppMenuItem(
                'Payroll',
                'Pages.UT.Payroll',
                'fa-regular n-fa-larger fa-money-check-dollar-pen',
                '',
                [],
                [
                    new AppMenuItem(
                        'Dashboard',
                        'Pages.UT.Payroll',
                        'fa-regular n-fa-larger fa-chart-line',
                        '/app/payroll/dashboard',
                    ),
                    new AppMenuItem(
                        'Rate Manager',
                        'Pages.UT.PayRates',
                        'fa-regular n-fa-larger fa-money-check-dollar-pen',
                        '/app/payroll/rate-manager',
                    ),
                ]
            ),

            new AppMenuItem(
                'Reports',
                'Pages.UT.Reports',
                'fa-regular n-fa-larger fa-chart-bar',
                '/app/report',
            ),

            new AppMenuItem(
                'Documents',
                'Pages.UT.Documents',
                'fa-regular n-fa-larger fa-file-alt',
                '/app/document',
            ),

            new AppMenuItem(
                'Com Logs',
                'Pages.UT.Communications.ComLogs',
                'fa-regular n-fa-larger fa-comment-quote',
                '/app/com-log/dashboard',
            ),

            new AppMenuItem(
                'Communications',
                'Pages.UT.Communications',
                'fa-regular n-fa-larger fa-tower-broadcast',
                '/app/communication',
            ),
            new AppMenuItem(
                'Notifications',
                '',
                'flaticon-alarm',
                '',
                [],
                [
                    new AppMenuItem(
                        'Inbox',
                        '',
                        'fa-regular n-fa-larger fa-x',
                        '/app/notifications'
                    ),
                    new AppMenuItem(
                        'MassNotifications',
                        'Pages.Administration.MassNotification',
                        'flaticon-paper-plane',
                        '/app/admin/mass-notifications'
                    )
                ]
            )
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                if (!subMenuItem.hasFeatureDependency()) {
                    return true;
                }

                if (subMenuItem.featureDependencySatisfied()) {
                    return true;
                }
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
