<div class="grid">
    <div class="col-2"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
    <div class="col-2">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="navigate('road-map')" type="button"><fa-icon [icon]="icon.farMapLocationDot" class="mr-1"></fa-icon>{{ 'Road Map' | localize }}</button>
    </div>
    <div class="col-2">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="navigate('dashboard')" type="button"><fa-icon [icon]="icon.farChartLine" class="mr-1"></fa-icon>{{ 'Dashboard' | localize }}</button>
    </div>
</div>