import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  template: `
  <div class="text-center">
    <span style="font-family: 'Special Elite', cursive !important;font-size:150px;color:darkred;">404</span><br>
    <span style="font-family: 'Special Elite', cursive !important;font-size:100px;color:silver;">Page not found</span><br>
    <button class="btn btn-outline-light" style="font-family: 'Special Elite', cursive !important;font-size:20px;color:darkred;" (click)="_appHeader.navigate('app/main/', 'dashboard',null)">Go Home</button>
      <!-- <img src="../assets/common/images/404file.jpg" alt="404" width="75%" class="img-fluid"> -->
  </div>
  `, 
  styles: [
   
  ]
})
export class PageNotFoundComponent extends AppComponentBase {
    constructor(
        injector: Injector
    ) {
        super(injector);
    }

}
