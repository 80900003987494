<div class="grid">
    <div class="col-2"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
    <div class="col-2" *ngIf="'Pages.UT.People.Person.Create' | permission">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route,'person-add-edit',logo)" type="button"><i class="fa fa-plus"></i>&nbsp;{{ 'Add Person' | localize }}</button>
    </div>
    <div class="col-2" *ngIf="'Pages.UT.People.Person' | permission">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route,'person-list',logo)" type="button"><i class="fa fa-list"></i>&nbsp;{{ 'List' | localize }}</button>
    </div>
    <div class="col-2">
        <button class="btn n-btn-sm-70w btn-outline w-100 btn-outline-dark" (click)="_appHeader.navigate(route,'dashboard',logo)" type="button"><fa-icon [icon]="icon.farChartLine" class="mr-1"></fa-icon>{{ 'Dashboard' | localize }}</button>
    </div>
</div>