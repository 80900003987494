import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'app-ts-top-menu',
  templateUrl: './ts-top-menu.component.html',
  styleUrls: ['./ts-top-menu.component.css'],
    animations: [appModuleAnimation()]
})
export class TsTopMenuComponent extends AppComponentBase {

    route: string = '/app/person/technical-specialist/';
    logo: string = '';

    constructor(
        injector: Injector) {
        super(injector);
    }
}
