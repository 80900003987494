import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'app-timecard-top-menu',
  templateUrl: './timecard-top-menu.component.html',
  styleUrls: ['./timecard-top-menu.component.css'],
    animations: [appModuleAnimation()]
})
export class TimecardTopMenuComponent extends AppComponentBase {

    route: string[] = [
        '/app/timecard/'
        ];
    logo: string = '';

    constructor(injector: Injector) {
        super(injector);
    }
    
}