import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
  selector: 'app-program-pratt-top-menu',
  templateUrl: './program-pratt-top-menu.component.html',
  styleUrls: ['./program-pratt-top-menu.component.css'],
    animations: [appModuleAnimation()]
})
export class ProgramPrattTopMenuComponent extends AppComponentBase {

    route: string = '/app/company/customer/program/pratt/';
logo: string = '';

    constructor(injector: Injector) {
        super(injector);
    }
    
}